import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Doorbell from './elements/Doorbell.js';
import EventPage from './elements/EventPage.js';
// import Home from './pages/Home.js';
import ContentPage from './pages/ContentPage.js';
import Navigation from './elements/Navigation.js'
import PhotoAndContent from './elements/PhotoAndContent.js';
import ErrorAndLoading from './elements/ErrorAndLoading.js';
import NoMatch from './elements/NoMatch.js';
import redirects from '../redirects.js';
import $ from 'jquery'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = { isClosed: false }
    this.triggerClick = this.triggerClick.bind(this);
    this.makeRedirects = this.makeRedirects.bind(this);
  }


  triggerClick() {
    let overlay = $('.overlay');
    let trigger = $('.hamburger');
    $('#wrapper').toggleClass('toggled');
    if (this.state.isClosed === true) { overlay.hide();
      trigger.removeClass('is-open');
      trigger.addClass('is-closed');
      this.setState({isClosed: false});
    } else {   
      overlay.show();
      trigger.removeClass('is-closed');
      trigger.addClass('is-open');
      this.setState({isClosed: true});
    }
  }
  
  makeRedirects() {
    let redir = redirects.map( (i, idx) => {
      if (i.newLink.substr(0, 4) === 'http')  {
        return <Route path={i.previousLink} component={
           () => window.location = i.newLink }/>
      } else {
        return <Redirect from={i.previousLink} to={i.newLink} key={idx}/>
      }
    })
    return redir
  }

  render() {
    return (
    <div id="wrapper">
        <div id="page-content-wrapper">
          <div className="container">
            <div className="row">
              <PhotoAndContent/>
              <div className="col-lg-8 col-md-6 col-sm-6" id="content">
                <ErrorAndLoading/>
                <Navigation/> 
                <div className="page">
                  <Switch>
                    {this.makeRedirects()}
                    <Route exact path='/doorbell' component={Doorbell}/>
                    <Route exact path='/event/:id' component={EventPage}/>
                    <Route path='/page/:pageId' component={ContentPage}/>
                    <Route exact path='/:type(|after|before)/:id?' 
                      render={props => <ContentPage override={'/About/About_Philo'}/>}/>
                    <Route component={NoMatch}/>
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default App
