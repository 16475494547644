import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { fetchPageContent,fetchPostContent } from '../../actions' 
import ReactMarkdown from 'react-markdown'
import Post from '../elements/Post.js'

class ContentPage extends Component {
  constructor(props) {
    super(props)
    this.getPagePosts = this.getPagePosts.bind(this)
  }

  componentWillMount() {
    const { dispatch } = this.props;
    if (this.props.override) {
      dispatch(fetchPageContent(this.props.override))
    } else {
      if (this.props.location) {
        dispatch(fetchPageContent(this.props.location.pathname))
      }
      if (!this.props.postsContent) {
        dispatch(fetchPostContent())
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch } = this.props
    if (!this.props.override && nextProps.override) {
      dispatch(fetchPageContent(nextProps.override))
    } else {
      if (this.props.location) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
          dispatch(fetchPageContent(nextProps.location.pathname))
        }
      }
    }
  }

  getPagePosts() {
    let pretitle = this.props.location.pathname.split('/')
    let title = '[' + pretitle[pretitle.length - 1] + ']'
    let pageKeys = Object.keys(this.props.postsContent)
    let filtered = pageKeys.filter( i => { return i.includes(title) } )
    filtered = filtered.sort().reverse()
    let retArr = []
    filtered.map( (i) => { return retArr.push(this.props.postsContent[i]) } )
    retArr = retArr.map( (i, idx) => { return (<Post text={i} key={idx}/>) })
    return retArr
  }

  render() {
    let content = ''
    let postsContent = ''
    if (this.props.isFetching === false && this.props.pageContent !== '') {
      content = <ReactMarkdown escapeHtml={false} source={this.props.pageContent}/>
    } else if (this.props.isFetching === false && this.props.pageContent === '') {
      content = 'No Page Information'
    }

    if (this.props.postsContent && Object.keys(this.props.postsContent).length > 0 && this.props.location.pathname.length > 0) {
      postsContent = this.getPagePosts()
    }
    return (
      <div>
        <section>
          {content}
          {postsContent}
        </section>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return Object.assign({}, state, ownProps.params)
}

export default withRouter(connect(mapStateToProps)(ContentPage))
