import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import phi from '../../styles/phi.png';

class PhotoAndContent extends Component {
  constructor(props) {
    super(props)
    this.formatText = this.formatText.bind(this);
    this.state = { imgNum: 1 }
  }

  formatText(str) {
    if (str) {
      let st = str.lastIndexOf('/');
      let sub = str.substring(st);
      return sub.replace(/_/g,' ')
        .replace(/\//g,'')
                .replace('.md','');
    } else {
      return 'Est. 1813'
    }
  }

  componentWillReceiveProps(n) {
    if (this.props.pagePath !== n.pagePath) {
      var rand = Math.floor(Math.random() * 11) + 1;
      $('#pics').css({backgroundImage: 'url(/images/panel_imgs/' + rand + '.jpg'})
    }
  }

  render() {
    return (
      <div className="col-lg-4 col-md-6 col-sm-6" id="pics" style={{ backgroundImage: 'url(/images/panel_imgs/' + this.state.imgNum + '.jpg'}}>
        <div id="picOverlay"></div>
        <div id="pageTitle">
          <h1 className="title">
            The <br/>
            Philomathean <br/>
            Society <br/>
          </h1>
          <h3 className="subtitle">{this.formatText(this.props.pagePath)}</h3>
          <br/><br/>
          <p>
            <a className='socmed'
               target="_blank"
               rel="noopener noreferrer"
               href="https://www.facebook.com/philohalls/">Facebook</a><br/>
            <Link
              className='socmed' 
               to="/page/About/Newsletter">Sign Up For Our Email List (Hebdo)</Link>
          </p>
          <div style={{ textAlign: 'center' }}>
          <img src={phi} height='60px' alt="logo" width='51px' style={{ margin: '0 auto', marginTop: '50px' }}/><br/>
          <span style={{ fontSize: '8px' }}>&copy; 2019: Philomathean Society | Created By:
            <a href="http://www.abhinavsuri.com" className='socmed' target="_blank" rel="noopener noreferrer">Abhinav Suri</a></span>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return { pagePath: state.pagePath }
}
export default connect(mapStateToProps)(PhotoAndContent)
