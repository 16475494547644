import React, { Component } from 'react';
import { connect } from 'react-redux';

class Event extends Component {

  render() {
    let stylePropVis = { visibility: this.props.isFetching ? 'visible': 'hidden', opacity: this.props.isFetching ? '1' : '0' }
    let stylePropErr = { visibility: this.props.err ? 'visible': 'hidden', opacity: this.props.err ? '1' : '0' }
    let errMessage = this.props.err ? this.props.err.message : 'No Error Message'
    return (
    <div>
      <div className="loading" style={stylePropVis}>
        <div className="loadMessage">
          <h1>Loading Content ...</h1>
        </div>
      </div>
      <div className="err" style={stylePropErr}>
        <div className="errMessage">
          <h1>Error</h1>  
          <p>{errMessage}. You may be offline or there may be an issue with the website. Contact technojoy@philomathean.org to get everything sorted out.</p>
        </div>
      </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { isFetching: state.isFetching, err: state.err }
}
export default connect(mapStateToProps)(Event);

