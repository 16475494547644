import React from 'react';
import { hydrate, render } from 'react-dom';
import Root from './components/Root';
import './styles/css/bootstrap.min.css';
import './styles/css/index.css';

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<Root />, rootElement)
} else {
  render(<Root />, rootElement)
}
