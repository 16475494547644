import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchNavigation } from '../../actions'

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.constructMenu = this.constructMenu.bind(this)
    this.formatText = this.formatText.bind(this)
    this.formatLink = this.formatLink.bind(this)
    this.getPath = this.getPath.bind(this)
    this.counter = 0;
    this.state = {
      open: false,
      imgName: Math.floor(Math.random() * 11),
      active: -1
    }
  }


  formatText(str) {
    str = str.replace(/_/gi, ' ');
    str = str.replace('.md', '')
    str = str.replace(/\[.\]]*/g, '');
    return str
  }

  formatLink(str) {
    str = str.replace('.md', '')
    return str;
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(fetchNavigation())
  }

  getPath(obj, val, path) {
    path = path || "";
    var fullpath = "";
    for (var b in obj) {
      if (obj[b] === val) {
        return (path + "/" + obj[b]);
      }
      else if (Array.isArray(obj[b])) {
        fullpath = this.getPath(obj[b], val, path + "/" + b) || fullpath;
      }
      else if (typeof obj[b] === "object") {
        fullpath = this.getPath(obj[b], val, path) || fullpath;
      }
    }
    return fullpath;
  }

  constructMenu(obj) { 
    if (obj) {
      let final = [];
      Object.keys(obj).forEach((i, idx) => {
        let name = i.replace('_', ' ').replace(/\[.\]]*/g, '')
        let elem = <li className="menu-title">
          <span className={`menu-item-header 
            ${this.state.active === idx  ? 'looking' : ''} 
            ${this.props.location.pathname.split('/').length >= 3 ? 
              (this.props.location.pathname.toLowerCase().split('/')[2]
                .indexOf(name.toLowerCase().replace(' ', '_')) > -1 ? 
                  'nav-active' : '') : ''}
            `} 
            onClick={() => {
                this.setState({
                  active: idx === this.state.active ? -1 : idx
                })
            }}
            >
            {name}
          </span>
          <ul className="collapsible" 
              style={{
                display: this.state.active === idx ? 'block' : 'none',
                visibility: this.state.active === idx ? 'visible' : 'hidden',
                opacity: this.state.active === idx ? 1 : 0, 
                height: this.state.active === idx ? 'auto' : 0
              }}>
            { obj[i].map((j) => {
                let text = this.formatText(j.filename)
                let path = this.formatLink(this.getPath(this.props.navigation, j.filename))
                return (<li onClick={() => {
                  this.setState({ 
                    open: false,
                    active: -1
                  })
                }} >
                  <NavLink to={{ pathname: `/page${path}` }}>{text}</NavLink>
                </li>)
              }) 
            }
          </ul>
        </li>
        final.push(elem);
      })

      return final;
    } else {
      return []
    }
  }

  render() {
    let { navigation } = this.props;
    navigation = navigation !== [{}] ? this.constructMenu(navigation) : '';
    return (
      <div className="nav-full">
      <div className="img-nav-contain">
        <div className="img-nav" style={{ 
          backgroundImage: 
          `url(/images/panel_imgs/${this.state.imgName}.jpg)`
        }}></div>
      </div>
        <ul className={`nav-sticky ${this.state.open ? 'open-m' : ''}`}>
          <h1 className="mobile-title">The
          <br/>Philomathean <br/>Society</h1>
          <span className="mobile-hamburger" 
              onClick={() => this.setState({ open: !this.state.open })}>
              {this.state.open ? "Close" : "Menu" }
              <div className={`mobile-hamburger-icon ${this.state.open ? 'open-h' : ''}`}></div>
          </span>
          <div className={`mobile-hidden-menu ${this.state.open ? 'show-m' : 'close-m'}`}>
            <li className="menu-title single">
              <NavLink strict={true} to="/" 
                className={this.props.location.pathname === '/' ? 'nav-active' : (
                  this.props.location.pathname.indexOf('after') > -1 ? 'nav-active' : (
                    this.props.location.pathname.indexOf('before') > -1 ? 'nav-active' : ''
                  )
                )} 
                onClick={() => {
                  this.setState({ 
                    open: false,
                    active: -1
                  })
                }}
                style={{ textDecoration: 'none' }}>Home</NavLink>
            </li>
            {navigation}
          </div>
        </ul>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { navigation: state.navigation }
}

export default withRouter(connect(mapStateToProps)(Navigation));
