import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NoMatch extends Component {

  render() {
    return (
    <div>
      <h1>Error 404 Not found</h1>
      <p>We recently updated our website (which broke some old links); however, all the content is still here, just under a different name. Feel free to explore the site</p>

      <h4><Link to="/">Go Home</Link></h4>
    </div>
    )
  }
}

export default NoMatch

