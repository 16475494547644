const redirects = [
  { previousLink: '/philadelphia-school-exhibit', newLink: '/page/Events/Philadelphia_School_Exhibit' },
  { previousLink: '/philly-school', newLink: '/page/Events/Philadelphia_School_Exhibit' },
  { previousLink: '/publications/hebdo', newLink: '/page/Publications/Hebdo' },
  { previousLink: '/apply', newLink: '/page/Joining/About_Membership' },
  { previousLink: '/hebdo', newLink: '/page/Publications/Hebdo' },
  { previousLink: '/wine-class', newLink: '/page/Events/Wine_Class' },
  { previousLink: '/reimburse', newLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd0QVNwHVQa-XIUYs_yg1k6C9Pd3Ug37_laV_lcudyRh6bPnQ/viewform?usp=sf_link' },
  { previousLink: '/payme', newLink: 'https://docs.google.com/forms/d/e/1FAIpQLSd0QVNwHVQa-XIUYs_yg1k6C9Pd3Ug37_laV_lcudyRh6bPnQ/viewform?usp=sf_link' },  
  { previousLink: '/wineclass1', newLink: 'https://www.facebook.com/events/471336426608552/' },
  { previousLink: '/wineclass2', newLink: 'https://www.facebook.com/events/598803893855543/' },
  { previousLink: '/wineclass3', newLink: 'https://www.facebook.com/events/681939762163372/' },
  { previousLink: '/wineclass4', newLink: 'https://www.facebook.com/events/235970510597442/' },
  { previousLink: '/wineclass5', newLink: 'https://www.facebook.com/events/1911851138899491/' },
  { previousLink: '/ao', newLink: 'https://philo-society.ticketleap.com/schmidtao'},
  { previousLink: '/ao-volunteer', newLink: 'https://docs.google.com/spreadsheets/u/1/d/1Q8vXNt4NkWw4ZRu9XiJ4QJodDlXqxrvcV_a2qQH95Mw/edit#gid=0' },
  { previousLink: '/page/Alumni/Rolls_of_Graduates_and_Honorees', newLink: '/page/Alumni/Roll_of_Senior_and_Nominal_Members' },
  { previousLink: '/payme', newLink: 'https://goo.gl/forms/6Bz0vueq1bpNoeER2' },
  { previousLink: '/2c', newLink: 'https://docs.google.com/spreadsheets/d/1chMY5EwrZD9iFnDt-QR3Np2pckelffXcifOtpMVN5QQ/edit#gid=0' },
  { previousLink: '/growing-pains', newLink: '/page/Events/Growing_Pains' },
  { previousLink: '/budget', newLink: 'https://docs.google.com/spreadsheets/u/3/d/1MaxJ3-cNvKfURDVnyGEE6QvZod2mLCXHnfC8ZjjjWFQ/edit?usp=sharing' },
  { previousLink: '/horcrux', newLink: 'https://docs.google.com/spreadsheets/d/1QeKxhbI01UAWoKhHoZiRgB-sTDp9CWrhTBVVYIUVU4I/edit#gid=0' },
  { previousLink: '/phacebook', newLink: 'https://philo-alumni-app.herokuapp.com' }, 
  { previousLink: '/era', newLink: '/page/Publications/Era' },
  { previousLink: '/submitera', newLink: 'https://docs.google.com/forms/d/e/1FAIpQLSdGA6v8M0QjmxZfzwI-x5P-5MWND3JJDH5xVg197-q2l0aing/viewform' },
  { previousLink: '/constitution', newLink: 'http://philomathean.org/images/about/Governing Documents of the Philomathean Society (04-06-2018).pdf' },
  { previousLink: '/era2020', newLink: 'http://philomathean.org/images/era/ERA%20Spring%202020.pdf' },
  { previousLink: '/dont-let-hope-burn-down', newLink: 'https://www.gofundme.com/f/don039t-let-hope-burn-down' },
]

export default redirects;
