import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown'
import { findDOMNode } from 'react-dom';
import $ from 'jquery';

class Post extends Component {
  constructor(props) {
    super(props)
    this.state = { showing: false }
    this.toggleShow = this.toggleShow.bind(this)
  }

  toggleShow() { 
    if (this.refs.indiv !== null) {
      this.setState({ showing : !this.state.showing })
      const el = findDOMNode(this.refs.indiv);
      let hiddenOrShowing = this.state.showing ? '150px' : 'auto'
      $(el).css({ 'height': hiddenOrShowing })
      $(el).children('#post-overlay').css({ display: this.state.showing ? 'block' : 'none' })
    }
    
  }

  render() {
    let showText = this.state.showing ? 'Hide' : 'Show'
    return (
      <div id="postContainer">
        <div id="post" ref="indiv">
          <div id="post-overlay"></div>
          <ReactMarkdown source={this.props.text}/>
        </div>
        <a id="show" href="#0" onClick={this.toggleShow}>{ showText } Post</a>
      </div>
    )
  }

}

export default Post
