import React, { Component } from 'react';
import doorbellUnpressed from './doorbell.png';
import doorbellPressed from './doorbell-pressed.png';

class Doorbell extends Component {
  constructor() {
    super();
    this.state = {
      notified: false,
      error: '',
      entrance: 'They are at *College Hall East (Fisher Fine Arts)*.',
      doorbell: doorbellUnpressed
    };
  }
  render() {
    const setLoc = (e) => {
      this.setState({ entrance: e.target.value });
    }
    const sendHook = () => {
      let name = document.getElementById('name').value;
      let time = localStorage.getItem('rang')
      let currT = +new Date()
      if (name.length === 0 || (time && (time/1000 + (60 * 1.813) > currT/1000))) {
        alert('Your name is too short/not filled out/you are spamming the system!');
      } else {
        window.localStorage.setItem('rang', +new Date())
        this.setState({ doorbell: doorbellPressed })
        setTimeout(() => {
          this.setState({ doorbell:  doorbellUnpressed })
        }, 108000)
        let basestr = `<!channel> *${document.getElementById('name').value}* NEEDS TO GET UP INTO COLLEGE HALL! \n`;
        basestr += this.state.entrance;
        basestr += ' \n Other Info: ' + 
          (document.getElementById('other').value.length > 0 ?  document.getElementById('other').value : 'None provided')
        fetch(`https://hooks.slack.com/services/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`, {
          method: 'POST',
          body: '{"text": "' + basestr + '"}'
        }).then(() => this.setState({ notified: true, error: ''}))
          .catch((err) => this.setState({ error: err.message }))
      }
    };
    return (
      <div>
        <h1>Doorbell!</h1>
        <p>Use this app to let Philos know you are waiting to get into the halls!</p>
        <strong>NOTE: ABUSE OF THIS APP WILL RESULT IN BEING BLOCKED. </strong><br/>
        <label>Your name: &nbsp;</label>
        <input type="text" id="name"/> &nbsp; (required)<br/>
        <h4>I am at...</h4>
        <input type="radio" name="loc" onClick={setLoc} value="*3rd Floor Halls Entrance (near elevator)*."/>
          <label>&nbsp; 3rd floor Philomathean Halls entrance (closest to elevator)</label><br/>
        <input type="radio" name="loc" onClick={setLoc} defaultChecked value="*College Hall East  (Fisher Fine Arts)*."/>
          <label>&nbsp; College Hall East (Fisher Fine Arts)</label><br/>
        <input type="radio" name="loc" onClick={setLoc} value="*College Hall West (Admissions entrance).*"/>
          <label>&nbsp; College Hall West (Admissions entrance)</label><br/>
        <label>Phone #/Contact Info: &nbsp; </label><input type="text" id="other"/><br/>
        <img src={this.state.doorbell} onClick={sendHook} alt='doorbbell' style={{ width: '100px', margin: 0 }} />
          Press the doorbell!<br/>
        {this.state.notified ? 
          `Notified philo members via Slack at ${new Date().toString()}! 
           Try again in 1.813 minutes` : ''}
         <br/>
         {this.state.error}
      </div>
    )
  }
}

export default Doorbell;
