const moment = require('moment');
const formatTime = (str, end) => {
  if (str && end) {
    let d = moment(str);
    let e = moment(end);
    let days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    let months = ['January','February','March','April','May','June','July','August','September','October','November','December']
    let hr = d.hour();
    let min = (d.minute()<10?'0':'') + d.minute()
    let eHr = e.hour();
    let eMin = (e.minute()<10?'0':'') + e.minute()
    let time = hr > 12 ? hr-12 + ":" + min + " PM" : hr + ":" + min + " AM";
    let etime = eHr > 12 ? eHr-12 + ":" + eMin + " PM" : eHr + ":" + eMin + " AM";
    if (e.date() === d.date()) {
      return `${days[d.day()]}, ${months[d.month()]} ${d.date()} ${d.year()} \u2022 ${time} - ${etime}`
    } else {
      return `${days[d.day()]}, ${months[d.month()]} ${d.date()} ${d.year()} ${time} - 
      ${days[e.day()]}, ${months[e.month()]} ${e.date()} ${e.year()} ${etime}`
    }
  }
}

export default formatTime;
