import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchEventInfo } from '../../actions'
import { withRouter, Link } from 'react-router-dom'
import formatTime from '../functions/date';

class EventPage extends Component {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(fetchEventInfo(this.props.match.params.id));
  }


  componentWillReceiveProps(nextProps) {
    const { dispatch } = this.props;
    if (this.props.match.params.id !== nextProps.match.params.id) {
      dispatch(fetchEventInfo(nextProps.match.params.id))
    }
  }


  render() {
    if (this.props.data) {
      let event = this.props.data[0]
      let backLink = <Link className="backlink" to='/home'>Go Home</Link>
      if (this.props.history.action === 'PUSH') {
        // eslint-disable-next-line
        backLink = <a className="backlink" onClick={() => this.props.history.goBack()}>Go Back</a>
      }
      let img = ''
      if (event.cover) {
        img = event.cover.source
      }
      let place = ''
      if (event.place) {
        place = event.place.name
      }

      return (
        <div>
          {backLink}
          <div className="imgHolder" style={{ backgroundImage: "url(" + img + ")" }}>
          </div>
          <h2 className="eventName">{event.name}</h2>
          <h4 className="time">{formatTime(event.start_time, event.end_time)}</h4>
          <h4 className="time">{place}</h4>
          <p>{event.description}</p>
          <h4 className="readMore"><a href={'https://facebook.com/events/' + event.id }>Event Link</a></h4>
        </div>
      )
    } else {
      return (
        <div>
          Loading...
        </div>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, state, ownProps.params);
}


export default withRouter(connect(mapStateToProps)(EventPage))
