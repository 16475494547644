import graph from 'fb-react-sdk';
import config from '../config';
import fetch from 'isomorphic-fetch'

export const GET_EVENTS = 'GET_EVENTS'
export const RECEIVE_EVENTS = 'RECEIVE_EVENTS'
export const SHOW_EVENT = 'SHOW_EVENT'
export const HIDE_EVENT = 'HIDE_EVENT'
export const GET_EVENT_INFO = 'GET_EVENT_INFO'
export const RECEIVE_EVENT_INFO = 'RECEIVE_EVENT_INFO'
export const GET_PAGE_CONTENT = 'GET_PAGE_CONTENT'
export const RECEIVE_PAGE_CONTENT = 'RECEIVE_PAGE_CONTENT'
export const GET_ALL_POSTS = 'GET_ALL_POSTS'
export const RECEIVE_ALL_POSTS = 'RECEIVE_ALL_POSTS'
export const GET_NAVIGATION = 'GET_NAVIGATION'
export const RECEIVE_NAVIGATION = 'RECEIVE_NAVIGATION'

export const SHOW_ERROR = 'SHOW_ERROR'
export const HIDE_ERROR = 'HIDE_ERROR'

export function showError(err) {
  return {
    type: SHOW_ERROR,
    err
  }
}

export function hideError() {
  return {
    type: HIDE_ERROR,
  }
}

export function getEvents(startIndex) {
  return {
    type: GET_EVENTS,
    startIndex
  }
}

export function showEvent(eventId) {
  return {
    type: SHOW_EVENT,
    eventId
  }
}

export function hideEvent(eventId) {
  return {
    type: HIDE_EVENT,
    eventId
  }
}

export function getEventInfo(eventId) {
  return {
    type: GET_EVENT_INFO,
    eventId
  }
}

export function receiveEvents(json) {
  return {
    type: RECEIVE_EVENTS,
    ...json
  }
}


export function receiveEventInfo(json, id) {
  return {
    type: RECEIVE_EVENT_INFO,
    id, 
    ...json
  }
}

export function getPageContent(path) {
  return {
    type: GET_PAGE_CONTENT,
    path
  }
}

export function receivePageContent(content) {
  return {
    type: RECEIVE_PAGE_CONTENT,
    content
  }
}

export function getAllPosts() {
  return {
    type: GET_ALL_POSTS,
  }
}

export function receiveAllPosts(content) {
  return {
    type: RECEIVE_ALL_POSTS,
    content
  }
}

export function getNavigation() {
  return {
    type: GET_NAVIGATION,
  }
}

export function receiveNavigation(content) {
  return {
    type: RECEIVE_NAVIGATION,
    content
  }
}

function graphGetAsync(url, param) {
  return new Promise(function(resolve, reject) {
    graph.get(url, param, function(err, res) {
      if (err !== null) reject(err);
      resolve(res)
    });
  });
}

export function fetchEvents(type, id) {
  return dispatch => {
    dispatch(getEvents(id))
    graph.setAccessToken(config.accessToken);
    return (graphGetAsync(`${config.pageID}/events`, {limit: 3, [type]:id}).then(function (res) {
        return res
      })
      .catch(function(err) {
        dispatch(showError(err))
      })
    ).then(function(res) {
      dispatch(hideError())
      dispatch(receiveEvents(res))
    })  
  }
}


export function fetchEventInfo(id) {
  return dispatch => {
    dispatch(getEventInfo(id))
    graph.setAccessToken(config.accessToken);
    graph.get(id, {fields: 'start_time, end_time,name,description,id,place,cover,attending_count,interested_count'}, function(err, res) {
      if (err) { return dispatch(showError(err)) }
      dispatch(hideError())
      dispatch(receiveEventInfo(res, id))
      dispatch(showEvent(id))
    })
  }
}

export function fetchPageContent(path) {
  return dispatch => {
    path =  path.replace('/page', '')
    dispatch(getPageContent(path))
    return ( 
      fetch(`${process.env.PUBLIC_URL}/content${path}.md`)
      .then(response => response.blob())
      .then(res => {
        function getContents() {
          let fileReader = new FileReader()
          return new Promise((resolve, reject) => {
            fileReader.onload = (event) => {
              resolve(event.target.result)
            }
            fileReader.onError = () => {
              reject(this)
            }
            fileReader.readAsText(res);
          });
        }

          return getContents()
      })
      .catch(err => showError(err))
      )
      .then(res => {
        dispatch(hideError());
        dispatch(receivePageContent(res))
      })
  }
}

function parseData(data) {
  var records = data.split(/\n/);
  var result = records.reduce(function(acc, record) {
    // eslint-disable-next-line
    var fields = record.match(/[^\/]+\/?/g) || [];
    var currentDir = acc;
       
    fields.forEach(function (field, idx) {

      // If field is a directory...
      if (/\/$/.test(field)) {
        field = field.replace('/','')

        // If first one and not an existing directory, add it
        if (idx === 0) {
          if (!(field in currentDir)) {
            currentDir[field] = [];
          }
          
          // Move into subdirectory
          currentDir = currentDir[field];
          
        // If not first, see if it's a subdirectory of currentDir
        } else {
          // Look for field as a subdirectory of currentDir
          var subDir = currentDir.filter(function(element){
            return typeof element === 'object' && element[field];
          })[0];
          
          // If didn't find subDir, add it and set as currentDir
          if (!subDir) {
            var t = Object.create(null);
            t[field] = [];
            currentDir.push(t);
            currentDir = t[field];
            
          // If found, set as currentDir
          } else {
            currentDir = subDir[field];
          }
        }
        
      // Otherwise it's a file. Make sure currentDir is a directory and not the root
      } else {
        if (Array.isArray(currentDir)) {
          currentDir.push({ filename: field });
        }      
      }
    });
    
    return acc;
    
  }, Object.create(null));
  return result;
}

export function fetchNavigation() {
  return dispatch => {
    dispatch(getNavigation())
// eslint-disable-next-line
    let paths = require.context('../../public/content', true, /.md$/).keys();
    let str = ''
// eslint-disable-next-line
    paths = paths.map( (i) => {
      str += '\n' + i.replace('./','')
    })
    let tree = parseData(str)
    dispatch(receiveNavigation(tree))
  }
}

export function fetchPostContent() {
  return dispatch => {
    dispatch(getAllPosts());
    let postTitles = require.context('../../public/_posts', false, /.md$/).keys();
    return Promise.all(postTitles.map(
      url => fetch(`${process.env.PUBLIC_URL}/_posts/${url}`)
        .then(response => response.blob())
        .then(res => { function getContents() {
          let fileReader = new FileReader()
          return new Promise((resolve, reject) => {
            fileReader.onload = (event) => {
              resolve(event.target.result)
            }
            fileReader.onError = () => {
              reject(this)
            }
            fileReader.readAsText(res);
          });
        }
        return getContents()
      })
      .catch(err => showError(err))
      )
    ).then(responses => { 
      let retObj = {}
      for(var i = 0; i < responses.length; i++) { retObj[postTitles[i]] = responses[i] }
      dispatch(hideError())
      dispatch(receiveAllPosts(retObj))
    })
  }
}


