import {
  GET_EVENTS,
  RECEIVE_EVENTS,
  SHOW_EVENT,
  HIDE_EVENT,
  GET_EVENT_INFO,
  RECEIVE_EVENT_INFO,
  GET_PAGE_CONTENT,
  RECEIVE_PAGE_CONTENT,
  GET_ALL_POSTS,
  RECEIVE_ALL_POSTS,
  SHOW_ERROR,
  HIDE_ERROR,
  GET_NAVIGATION,
  RECEIVE_NAVIGATION
} from '../actions'

const initialState = {
  isFetching: true
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_ERROR:
      return Object.assign({}, state, {
        err: action.err,
        isFetching: false
      })
    case HIDE_ERROR:
      return Object.assign({}, state, {
        err: null
      })
    case GET_EVENTS:
      return Object.assign({}, state, {
        pagePath: '',
        isFetching: true
      })
    case RECEIVE_EVENTS:
      let { data, paging } = action;
      return Object.assign({}, state, {
        data,
        paging,
        isFetching: false
      })
    case SHOW_EVENT:
      return Object.assign({}, state, {
        data: state.data.map( (i) => {
          if (i.id === action.eventId) {
            return Object.assign({}, i, { showing: true })
          } else {
            return Object.assign({}, i)
          }
        })
      })
    case HIDE_EVENT:
      return Object.assign({}, state, {
        data: state.data.map( (i) => {
          if (i.id === action.eventId) {
            return Object.assign({}, i, { showing: false })
          } else {
            return Object.assign({}, i)
          }
        })
      })
    case GET_EVENT_INFO:
      if (data) {
      return Object.assign({}, state, {
        isFetching: true,
        data: state.data.map( (i) => {
          if (i.id === action.eventId) {
            return Object.assign({}, i, { isFetching: true })
          } else {
            return Object.assign({}, i)
          }
        })
      })
      } else {
        return Object.assign({}, state, {
          isFetching: true,
          data: [Object.assign({}, {id: action.eventId, isFetching: true})]
        })
      }
    case RECEIVE_EVENT_INFO:
      let { t, id, ...rest } = action
      return Object.assign({}, state, {
        err: null,
        isFetching: false,
        data: state.data.map( (i) =>  {
          if (i.id === id) {
            return Object.assign({}, i, rest, { id: i.id, isFetching: false })
          } else {
            return Object.assign({}, i)
          }
        })
      })
    case GET_PAGE_CONTENT:
      return Object.assign({}, state, {
        pagePath: action.path,
        pageContent: '',
        isFetching: true
      })
    case RECEIVE_PAGE_CONTENT:
      return Object.assign({}, state, {
        pageContent: action.content,
        isFetching: false,
      })
    case GET_ALL_POSTS:
      return Object.assign({}, state, {
        postsContent: {},
        isFetching: true
      })
    case RECEIVE_ALL_POSTS:
      return Object.assign({}, state, {
        postsContent: action.content,
        isFetching: false,
      })
    case GET_NAVIGATION:
      return Object.assign({}, state, {
        navigation: {},
        isFetching: true
      })
    case RECEIVE_NAVIGATION:
      return Object.assign({}, state, {
        navigation: action.content,
        isFetching: false,
      })
    default:
      return state
  }
}

